// This is just a mandatory env list to validate during project initialization

export const MANDATORY_ENVS = [
  { id: 'NEXT_PUBLIC_STORE_ENV', procEnv: process.env.NEXT_PUBLIC_STORE_ENV },
  {
    id: 'NEXT_PUBLIC_APP_VERSION',
    procEnv: process.env.NEXT_PUBLIC_APP_VERSION,
  },
  { id: 'NEXT_PUBLIC_API_URL', procEnv: process.env.NEXT_PUBLIC_API_URL },
  {
    id: 'NEXT_PUBLIC_STORE_DOMAIN',
    procEnv: process.env.NEXT_PUBLIC_STORE_DOMAIN,
  },
  {
    id: 'NEXT_PUBLIC_CDN_BASE_PATH',
    procEnv: process.env.NEXT_PUBLIC_CDN_BASE_PATH,
  },
  { id: 'NEXT_PUBLIC_APIKEY', procEnv: process.env.NEXT_PUBLIC_APIKEY },
  {
    id: 'NEXT_PUBLIC_AUTH_DOMAIN',
    procEnv: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  },
  {
    id: 'NEXT_PUBLIC_DATABASE_URL',
    procEnv: process.env.NEXT_PUBLIC_DATABASE_URL,
  },
  { id: 'NEXT_PUBLIC_PROJECT_ID', procEnv: process.env.NEXT_PUBLIC_PROJECT_ID },
  {
    id: 'NEXT_PUBLIC_STORAGE_BUCKET',
    procEnv: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  },
  {
    id: 'NEXT_PUBLIC_MESSAGIN_SENDER_ID',
    procEnv: process.env.NEXT_PUBLIC_MESSAGIN_SENDER_ID,
  },
  { id: 'NEXT_PUBLIC_APP_ID', procEnv: process.env.NEXT_PUBLIC_APP_ID },
  {
    id: 'NEXT_PUBLIC_MEASUREMENT_ID',
    procEnv: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
  },
  { id: 'NEXT_PUBLIC_REPORT_URL', procEnv: process.env.NEXT_PUBLIC_REPORT_URL },
  { id: 'NEXT_PUBLIC_GA_UA_CODE', procEnv: process.env.NEXT_PUBLIC_GA_UA_CODE },
  {
    id: 'NEXT_PUBLIC_PORTAL_SELLER_WEBAPI',
    procEnv: process.env.NEXT_PUBLIC_PORTAL_SELLER_WEBAPI,
  },
  {
    id: 'NEXT_PUBLIC_MARKETPLACEIN_WEBAPI',
    procEnv: process.env.NEXT_PUBLIC_MARKETPLACEIN_WEBAPI,
  },
  {
    id: 'NEXT_PUBLIC_PUBLIC_STRAPI_API_URL',
    procEnv: process.env.NEXT_PUBLIC_PUBLIC_STRAPI_API_URL,
  },
  { id: 'NEXT_PUBLIC_SENTRY_DSN', procEnv: process.env.NEXT_PUBLIC_SENTRY_DSN },
  {
    id: 'NEXT_PUBLIC_KEYCLOAK_RESOURCE',
    procEnv: process.env.NEXT_PUBLIC_KEYCLOAK_RESOURCE,
  },
  {
    id: 'NEXT_PUBLIC_KEYCLOAK_ISSUER',
    procEnv: process.env.NEXT_PUBLIC_KEYCLOAK_ISSUER,
  },
  {
    id: 'NEXT_PUBLIC_KEYCLOAK_SECRET',
    procEnv: process.env.NEXT_PUBLIC_KEYCLOAK_SECRET,
  },
  {
    id: 'NEXT_PUBLIC_KEYCLOAK_BASE_URL',
    procEnv: process.env.NEXT_PUBLIC_KEYCLOAK_BASE_URL,
  },
  {
    id: 'NEXT_PUBLIC_UNLEASH_PROXY_URL',
    procEnv: process.env.NEXT_PUBLIC_UNLEASH_PROXY_URL,
  },
  {
    id: 'NEXT_PUBLIC_UNLEASH_PROXY_KEY',
    procEnv: process.env.NEXT_PUBLIC_UNLEASH_PROXY_KEY,
  },
  {
    id: 'NEXT_PUBLIC_NPS_FORM_URL',
    procEnv: process.env.NEXT_PUBLIC_NPS_FORM_URL,
  },
  {
    id: 'NEXT_PUBLIC_HOTJAR',
    procEnv: process.env.NEXT_PUBLIC_HOTJAR,
  },
];
