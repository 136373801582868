import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

export const PageLayout = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
`;

export const Content = styled.div<{ expandedMenu: boolean }>`
  ${({ theme, expandedMenu }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: ${theme.color.neutral[200]};
    margin-left: ${expandedMenu ? '240px' : '88px'};

    ${media.lessThan('large')} {
      margin-left: ${theme.spacing[0]};
    }
  `}
`;
