import axios, {
  AxiosRequestConfig,
  AxiosResponseHeaders,
  RawAxiosRequestHeaders,
} from 'axios';
import { format } from 'date-fns';
import { getSession } from 'next-auth/react';
import { KeycloakGroupType } from '@/common/types/keycloak';
import { formatErrorResponse } from './httpClientCentauro';

export type RequestHeaders = RawAxiosRequestHeaders & {
  token?: string;
  timeout?: number;
};

export type HttpClientResponse = {
  error: undefined;
  data: Record<string, unknown>;
  status: number;
  statusText: string;
  headers: AxiosResponseHeaders;
  config: AxiosRequestConfig<unknown>;
  request?: Record<string, unknown>;
};

const getAuthHeader = () => {
  if (typeof window === 'undefined') return '';

  const currentAccount =
    localStorage.getItem('currentAccount') !== 'undefined'
      ? localStorage.getItem('currentAccount') || ''
      : '{}';
  const currentAccountParsed: KeycloakGroupType = JSON.parse(
    currentAccount || '{}',
  );

  const result =
    currentAccountParsed && Object.keys(currentAccountParsed).length > 0
      ? JSON.parse(currentAccount.replace('Bearer ', '')).token
      : '';

  localStorage.removeItem('invalidSession');
  return result;
};

const getJson = async (
  url: string,
  idToken?: string,
  secondaryHeader?: RequestHeaders,
  withCredentials = false,
  options = {},
) => {
  const session = await getSession();
  const headers = {
    ...secondaryHeader,
    Authorization: `Bearer ${session?.accessToken}`,
  };

  if (getAuthHeader()) headers.token = getAuthHeader();

  try {
    const response = await axios.get(url, {
      ...options,
      headers,
      withCredentials,
    });
    const payload = { ...response, error: undefined };

    if (process.env.NEXT_PUBLIC_DEBUG_HTTP) {
      console.log(
        `INFO ${format(new Date(), 'dd/MM/yyyy hh:mm:ss')} ${url}:`,
        payload,
      );
    }

    return payload;
  } catch (error) {
    if (error instanceof Error) {
      return formatErrorResponse(error, url);
    }

    return {
      data: {},
      error: error,
      status: '',
      statusText: '',
      headers: {},
      config: {},
    };
  }
};

const postJson = async (
  url: string,
  body: unknown,
  idToken?: string,
  secondaryHeader?: RequestHeaders,
  withCredentials = true,
  options = {},
) => {
  const session = await getSession();
  const headers = {
    ...secondaryHeader,
    token: getAuthHeader(),
    Authorization: `Bearer ${session?.accessToken}`,
  };

  try {
    const response = await axios.post(url, body, {
      ...options,
      headers,
      withCredentials,
    });
    const payload = { ...response, error: undefined };

    if (process.env.NEXT_PUBLIC_DEBUG_HTTP) {
      console.log(
        `INFO ${format(new Date(), 'dd/MM/yyyy hh:mm:ss')} ${url}:`,
        payload,
      );
    }

    return payload;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return formatErrorResponse(error, url);
    }

    return {
      data: {},
      error: error,
      status: '',
      statusText: '',
      headers: {},
      config: {},
    };
  }
};

const putJson = async (
  url: string,
  body: unknown,
  idToken?: string,
  secondaryHeader?: RequestHeaders,
  withCredentials = false,
  options = {},
) => {
  const session = await getSession();
  const headers = {
    ...secondaryHeader,
    token: getAuthHeader(),
    Authorization: `Bearer ${session?.accessToken}`,
  };

  try {
    const response = await axios.put(url, body, {
      ...options,
      headers,
      withCredentials,
    });

    const payload = { ...response, error: undefined };

    if (process.env.NEXT_PUBLIC_DEBUG_HTTP) {
      console.log(
        `INFO ${format(new Date(), 'dd/MM/yyyy hh:mm:ss')} ${url}:`,
        payload,
      );
    }

    return payload;
  } catch (error) {
    if (error instanceof Error) {
      return formatErrorResponse(error, url);
    }

    return {
      data: {},
      error: error,
      status: '',
      statusText: '',
      headers: {},
      config: {},
    };
  }
};

export { getJson, postJson, putJson };
