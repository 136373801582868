import { Typography } from '@grupo-sbf/motriz-core';
import { Root, Video } from './VideoBox.styled';

export type VideoBoxProps = {
  title: string;
  embeddedId: string;
  large?: boolean;
};

function VideoBox({ title, embeddedId, large = false }: VideoBoxProps) {
  return (
    <>
      <Root tabIndex={0} large={large}>
        <Video>
          <iframe
            sandbox="allow-same-origin allow-scripts allow-popups"
            width={large ? '550' : '275'}
            height={large ? '322' : '161'}
            src={`https://www.youtube.com/embed/${embeddedId}`}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Video>
        <Typography type="subtitle" size="small">
          {title}
        </Typography>
      </Root>
    </>
  );
}

export default VideoBox;
