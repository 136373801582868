import { DataLayerEvent } from './trackingEvents.types';

export const pushDataLayer = (objDataLayer: DataLayerEvent) => {
  if (typeof window === 'undefined') return;

  window.dataLayer = window.dataLayer || [];

  const exists = window.dataLayer.some((item) => {
    return (
      typeof item.event === 'string' &&
      typeof item.page_url === 'string' &&
      typeof item.page_type === 'string' &&
      item.event === objDataLayer.event &&
      item.page_url === objDataLayer.page_url &&
      item.page_type === objDataLayer.page_type
    );
  });

  if (exists) return;

  window.dataLayer.push(objDataLayer);
};

const formatItemId = (componentType: string, title: string) => {
  return `menu_${componentType}_${title.toLowerCase().replace(/\s+/g, '_')}`;
};

export const sendEvent = (
  eventName: string,
  eventData: Partial<DataLayerEvent> = {},
) => {
  const componentType =
    eventData.micro_component === 'main_menu' ? 'main_menu' : 'submenu';
  const objEvent: DataLayerEvent = {
    event: eventName,
    page_url: eventData.page_url || window.location.href,
    page_type: eventData.page_type || '',
    event_type: eventData.event_type,
    component: eventData.component || '',
    micro_component: eventData.micro_component || '',
    link: eventData.link,
    content_type: eventData.content_type,
    item_id: formatItemId(componentType, eventData.link?.anchor_text || ''),
    timestamp: eventData.timestamp || new Date(),
  };

  pushDataLayer(objEvent);
};

export const sendPageView = (page_url: string, page_type: string) => {
  sendEvent('pageView', { page_url, page_type });
};
