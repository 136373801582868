import { Accordion, AccordionItem, Divider } from '@grupo-sbf/motriz-core';
import {
  MenuHamburger,
  Refresh,
  ExternalLink,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  ChevronUp,
} from '@grupo-sbf/motriz-icons/centauro';
import { usePathname, useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { useState, useMemo } from 'react';
import useUnleash from '@/common/hooks/useUnleash';
import { signOutAndDestroySession } from '@/common/utils';
import { sendEvent } from '@/common/utils/tracking/trackingEvents';
import { LinkData } from '@/common/utils/tracking/trackingEvents.types';
import { adminUserPath } from '@/paths';
import {
  Sidebar,
  MenuItem,
  Trace,
  FooterMenu,
  ExpandingButton,
  ContentAccordion,
  Overlay,
  SkeletonMenu,
} from './Menu.styled';
import { MENU, SELLER_SESSION } from './constants';
import { ContentMenu, Props } from './types';

const Menu = ({
  toggleSellerModal,
  isMenuOpen,
  closeMenu,
  setExpandedMenu,
  expandedMenu,
}: Props) => {
  const pathname = usePathname();
  const router = useRouter();
  const [expandedItem, setExpandedItem] = useState<string>('');
  const { data: session, status } = useSession();
  const nameUser = session?.user?.name;
  const isAdmin = session?.isAdmin;
  const isShippingManagementActive = useUnleash(
    'portal-seller-front.shipping-management',
  );

  const isFaq = (content: ContentMenu) => {
    return (
      pathname.includes('faq') && content.some((item) => item.title === 'FAQ')
    );
  };

  const updatedMenu = useMemo(() => {
    return MENU.map((item) => {
      let updatedItem = { ...item };

      if (item.title === 'Configurações' && isAdmin) {
        updatedItem = {
          ...updatedItem,
          content: [
            ...updatedItem.content,
            { title: 'Cadastrar usuários', link: adminUserPath, active: false },
          ],
        };
      }

      if (updatedItem.content?.some((content) => pathname === content.link)) {
        setExpandedItem(item.title);
        updatedItem = {
          ...updatedItem,
          content: updatedItem.content.map((content) => ({
            ...content,
            active: pathname === content.link,
          })),
        };
      }

      if (!isShippingManagementActive) {
        updatedItem.content = updatedItem.content?.filter(
          (content) => content.title !== 'Gestão de envios',
        );
      }

      return updatedItem;
    });
  }, [pathname, isAdmin, isShippingManagementActive]);

  const handleMenuClick = (
    title: string,
    href: string,
    hasSubmenus: boolean,
    submenuTitle?: string,
  ) => {
    setExpandedItem(title);

    const linkData: LinkData = {
      anchor_text: submenuTitle || title,
      title: submenuTitle || title,
    };

    sendEvent('select_content', {
      event_type: 'click',
      component: 'menu',
      micro_component: hasSubmenus ? 'main_menu' : 'submenu',
      link: linkData,
      content_type: 'navigation_link',
      timestamp: new Date(),
    });

    if (!hasSubmenus) {
      router.push(href);
      if (isMenuOpen) {
        closeMenu();
      }
    }
  };

  const toggleExpansive = () => {
    if (isMenuOpen) {
      closeMenu();
      return;
    }
    setExpandedItem('');
    setExpandedMenu((prevState) => !prevState);
  };

  const handleExpandAccordion = (heading: string) => {
    if (heading !== pathname) {
      setExpandedItem(heading);
      setExpandedMenu(true);
    }
  };

  if (status === 'loading') {
    return <SkeletonMenu type="rectangle" size={['300px', '100%']} />;
  }

  return (
    <>
      <Sidebar isMenuOpen={isMenuOpen} expanded={expandedMenu}>
        <Accordion topBorder={false} bottomBorder={false}>
          <ContentAccordion>
            <AccordionItem
              headerId="seller"
              panelId="seller-painel"
              heading={nameUser && expandedMenu ? nameUser : ''}
              expandedIcon={<div />}
              closedIcon={<div />}
              headingIcon={<MenuHamburger />}
              headingSize="xlarge"
              headingColor="neutral"
              expanded={expandedItem === 'Nome do seller'}
              onExpand={() => handleExpandAccordion('Nome do seller')}
            >
              <MenuItem
                as="p"
                onClick={() => {
                  toggleSellerModal();
                  if (isMenuOpen) {
                    closeMenu();
                  }
                }}
                isSelected={false}
              >
                <Refresh /> {SELLER_SESSION.changeSeller}
              </MenuItem>
              <MenuItem
                as="p"
                onClick={signOutAndDestroySession}
                isSelected={false}
              >
                <ExternalLink />
                {SELLER_SESSION.logOut}
              </MenuItem>
            </AccordionItem>
            {updatedMenu.map((item) => {
              const hasSubmenus = !!item.content?.length;
              const isActiveItem =
                pathname === item.link ||
                item.content.some((content) => content.active) ||
                isFaq(item.content);

              return (
                <div
                  key={item.title}
                  onClick={() =>
                    handleMenuClick(item.title, item.link, hasSubmenus)
                  }
                  aria-hidden="true"
                >
                  <AccordionItem
                    headerId={isActiveItem ? 'menu-selected' : 'menu'}
                    heading={expandedMenu ? item.title : ''}
                    expandedIcon={
                      hasSubmenus ? <ChevronUp id="icon-chevron" /> : <div />
                    }
                    closedIcon={
                      hasSubmenus ? <ChevronDown id="icon-chevron" /> : <div />
                    }
                    disabled={!hasSubmenus}
                    headingIcon={
                      <>
                        <Trace isSelected={isActiveItem}>|</Trace>
                        <item.icon />
                      </>
                    }
                    expanded={expandedItem === item.title}
                    onExpand={() => handleExpandAccordion(item.title)}
                  >
                    {item.content?.map((content) => {
                      return (
                        <MenuItem
                          key={content.title}
                          isSelected={
                            pathname === content.link ||
                            content.active ||
                            (pathname.includes('faq') &&
                              content.title === 'FAQ')
                          }
                          href={content.link}
                          target={
                            content.title === 'ServiceNow' ? '_blank' : '_self'
                          }
                          onClick={() => {
                            handleMenuClick(
                              item.title,
                              content.link,
                              false,
                              content.title,
                            );
                            if (isMenuOpen) {
                              closeMenu();
                            }
                          }}
                        >
                          {content.title}
                        </MenuItem>
                      );
                    })}
                  </AccordionItem>
                </div>
              );
            })}
          </ContentAccordion>
        </Accordion>
        <FooterMenu>
          <Divider />
          <ExpandingButton data-testid="expanding" onClick={toggleExpansive}>
            {expandedMenu ? <ChevronLeft /> : <ChevronRight />}
          </ExpandingButton>
        </FooterMenu>
      </Sidebar>
      {isMenuOpen && <Overlay onClick={closeMenu} />}
    </>
  );
};

export default Menu;
