import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Markdown from '@/common/components/Markdown/Markdown';
import Modal from '@/common/components/Modal';
import {
  StrapiAnnouncements,
  StrapiNews,
  StrapiNPS,
} from '@/common/types/strapi';
import FormNPS from './HardcodedTemplates/nps/FormNps';
import { StyledSubtitle, StyledVideoBox } from './NoticesModal.styled';
import { NoticesModalProps, NoticeType } from './NoticesModal.types';
import { embedIDFrom } from './NoticesModal.utils';

const NoticesModal = ({
  title,
  notices,
  isMobile,
  openButton,
  type,
}: NoticesModalProps): JSX.Element => {
  const router = useRouter();

  const shouldShowContent = (pageUrl: string | undefined): boolean => {
    const currentPath = router.pathname;
    let currentFullUrl = '';

    if (typeof window !== 'undefined') {
      currentFullUrl = window.location.href;
    }

    if (!pageUrl) return true;

    if (currentPath === pageUrl || pageUrl === currentFullUrl) {
      return true;
    }

    return false;
  };

  const canShowButton =
    type === NoticeType.News
      ? notices.some((notice) =>
          shouldShowContent((notice as StrapiNews).attributes?.pageUrl),
        )
      : true;

  const startOpenModal = (notices as StrapiNews[]).some(
    (notice) =>
      notice.attributes.startOpen &&
      shouldShowContent(notice.attributes.pageUrl),
  );

  const isOpen =
    type === NoticeType.News ? startOpenModal : !isMobile && !openButton;

  const [open, setOpen] = useState<boolean>(isOpen);

  const handleVisibility = (): void => {
    const noticesParsed: string = JSON.stringify(notices);

    switch (type) {
      case NoticeType.Announcement:
        localStorage.setItem(`announcementAlreadyOpened`, `${noticesParsed}`);
        return setOpen(!open);
      case NoticeType.NPS:
        localStorage.setItem(`npsAlreadyOpened`, `${noticesParsed}`);
        return setOpen(!open);
      case NoticeType.News:
        return setOpen(!open);
      default:
        return setOpen(!open);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [router.pathname, isOpen]);

  const ItemTemplate = () => (
    <>
      {notices.map((notice) => {
        const titleItem =
          (notice as StrapiAnnouncements).attributes.titulo ||
          (notice as StrapiNews).attributes.title;
        const video =
          (notice as StrapiAnnouncements).attributes.youtubeVideo ||
          (notice as StrapiNews).attributes.youtubeUrl;
        const content =
          (notice as StrapiAnnouncements).attributes.conteudo ||
          (notice as StrapiNews).attributes.content;
        const pageUrl = (notice as StrapiNews).attributes.pageUrl;
        const buttonText = (notice as StrapiNPS).attributes.buttonText;
        const buttonUrl = (notice as StrapiNPS).attributes.buttonUrl;

        if (type === NoticeType.News && !shouldShowContent(pageUrl)) {
          return;
        }

        return (
          <div key={notice.id}>
            {type !== NoticeType.NPS ? (
              <>
                <StyledSubtitle>{titleItem}</StyledSubtitle>
                {video && (
                  <StyledVideoBox
                    large
                    title={''}
                    embeddedId={embedIDFrom(video)}
                  />
                )}
                <Markdown>{content}</Markdown>
              </>
            ) : (
              <FormNPS
                onClose={handleVisibility}
                title={titleItem}
                content={content}
                buttonText={buttonText}
                buttonUrl={buttonUrl}
              />
            )}
          </div>
        );
      })}
    </>
  );

  return (
    <Modal
      title={title}
      modalTitle={title}
      beforeClose={() => handleVisibility()}
      hasHeight={true}
      customContent={
        <div>
          <ItemTemplate />
        </div>
      }
      openButton={
        openButton && canShowButton
          ? {
              icon: openButton?.icon,
              text: isMobile ? '' : title,
            }
          : undefined
      }
      isOpen={open}
    />
  );
};

export default NoticesModal;
