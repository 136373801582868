import { LayoutContextProvider, ThemeProvider } from '@grupo-sbf/motriz-core';
import sbfTheme from '@grupo-sbf/motriz-tokens/themes/sbf';
import { BreakpointProvider } from '../../common/hooks/useBreakpoint/index';
import {
  BreakpointServerType,
  BreakpointProviderProps,
} from '../../common/hooks/useBreakpoint/types/index';

export function MotrizProvider({
  children,
  initialBreakpoint,
}: BreakpointProviderProps) {
  return (
    <ThemeProvider theme={sbfTheme} customTheme={{}}>
      <LayoutContextProvider>
        <BreakpointProvider
          initialBreakpoint={initialBreakpoint as BreakpointServerType}
        >
          <>{children}</>
        </BreakpointProvider>
      </LayoutContextProvider>
    </ThemeProvider>
  );
}
