import { getJson } from './httpClient/httpClientBff';

const url = `${process.env.NEXT_PUBLIC_PUBLIC_STRAPI_API_URL}`;

const getAnnouncement = async (token: string) => {
  try {
    const req = await getJson(
      `${url}/announcements?sort=id%3Adesc&pagination[pageSize]=1`,
      token,
    );

    return req.data?.data ? req.data?.data : req;
  } catch {
    console.error(`getAnnouncement: Something went wrong`);
    return [];
  }
};

const getNews = async (token: string) => {
  try {
    const req = await getJson(`${url}/news?sort=id%3Adesc`, token);

    return req.data?.data ? req.data?.data : req;
  } catch {
    console.error(`getNews: Something went wrong`);
    return [];
  }
};

const getTutorials = async (token: string) => {
  try {
    const req = await getJson(`${url}/tutorials?sort=id%3Adesc`, token);

    return req.data?.data ? req.data?.data : req;
  } catch {
    console.error(`getTutorials: Something went wrong`);
    return [];
  }
};

const getNPS = async (token: string) => {
  try {
    const req = await getJson(`${url}/nps?sort=id%3Adesc`, token);

    return req.data?.data ? req.data?.data : req;
  } catch {
    console.error(`getNPS: Something went wrong`);
    return [];
  }
};

export { getAnnouncement, getNews, getTutorials, getNPS };
